<mat-card id="component-container" style="padding-bottom: 1px;">
  <mat-card>
    <h1 id="title">Peptide Logo Generation</h1>
  </mat-card>
  <mat-card class="input_card">
    <p>Enter your sequence for generating a logo</p>
    <form>
      <mat-form-field appearance="outline" style="margin-left: 10px; width: 100%; height: auto;">
        <mat-label>Input Sequence</mat-label>
        <textarea
          matInput
          name="organism_input"
          [(ngModel)]="organisim_sequence"
          rows="4"
        >
        </textarea>
      </mat-form-field>
      <button mat-button class="button_search" (click)="getData();">
        Generate
      </button>
    </form>
    <div id="logo" [hidden]="!showLogo" style="width: 50%; margin: auto; margin-top: 5% !important; margin-bottom: 5% !important;"></div>
    <p *ngIf="errorMessage">
      Due to the length of the sequence or the insufficient input of proteins, we were unable to generate the motif.
    </p>
  </mat-card>
</mat-card>
