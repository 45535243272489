import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  dialogWidth: number = 600; // Default dialog width
  proteinName: string; // Protein name (first name from the array or directly from string)
  question: string; // Static question with protein name
  messageContent: string; // Message to display in the modal
  showWait: boolean = false; // Spinner/loading indicator
  showDialogBox: string = 'block'; // Display state of the dialog box

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { protein: string | string[] }, // Adjusted type to accept string or array
    private httpClient: HttpClient, // HttpClient for making API calls
    private dialogRef: MatDialogRef<ModalComponent> // Reference to the dialog
  ) {
    // Check if protein is an array or a string
    if (Array.isArray(data.protein)) {
      this.proteinName = data.protein[0] || 'unknown protein'; // Use the first name if it's an array
    } else if (typeof data.protein === 'string') {
      this.proteinName = data.protein; // Use the string directly if it's not an array
    } else {
      this.proteinName = 'unknown protein'; // Fallback for invalid input
    }

    // Construct the static question with the protein name
    this.question = `What's the function of ${this.proteinName}.`;

    console.log("Protein Name:", this.proteinName);
    console.log("Constructed Question:", this.question);

    // Fetch data for the given protein question
    this.getMessageData(this.question);
  }

  ngOnInit(): void {}

  // Dynamically updates the dialog width
  updateDialogWidth(newWidth: number): void {
    this.dialogWidth = newWidth;
  }

  // Closes the dialog box
  closeDialog(): void {
    this.dialogRef.close();
    this.showDialogBox = 'none';
  }

  // Fetches message data for the given question
  getMessageData(question: string): void {
    console.log("Fetching data for question:", question);

    const apiUrl = `${environment.BASE_API_URL}chatgpt/?content=${encodeURIComponent(question)}`;

    console.log("Request URL:", apiUrl);

    this.showWait = true;

    // Make the API request
    forkJoin([this.fetchJson(apiUrl)]).subscribe({
      next: (result) => {
        this.showWait = false;
        const apiResponse: any = result[0]; // Treat API response as dynamic type
        console.log("API Response:", apiResponse);

        if (!apiResponse?.data?.length) {
          // Handle empty or invalid responses gracefully
          this.messageContent = "No valid response from the API. Please check your input.";
          console.warn("API returned empty data or invalid input:", apiResponse);
          return;
        }

        // Assign the fetched data to the messageContent property
        this.messageContent = apiResponse.data;
        this.updateDialogWidth(1000); // Adjust dialog width after loading content
      },
      error: (err) => {
        this.showWait = false;
        console.error("Error fetching message data:", err);
        this.messageContent = "Failed to fetch data from the API.";
      }
    });
  }

  // Makes a GET request to fetch data from the given API endpoint
  fetchJson(url: string) {
    console.log("In fetchJson");
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };

    // Return the observable after making the HTTP request
    return this.httpClient.get(url, options).pipe(
      map((response) => {
        console.log("In Map:", response);
        return response;
      })
    );
  }
}
