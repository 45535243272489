<br>
<mat-card id="component-container" *ngIf="!pubmedID">
  <!-- Dataset View -->
  <mat-card id="title-container">
    <h1 id="title">Datasets</h1>
  </mat-card>

  <mat-card id="title-container" style="margin-top: 10px;">
    <!-- Loading State -->
    <div *ngIf="!ELEMENT_DATA && !requestError">
      <p style="text-align: center; margin: 20px 0">Fetching summary data...</p>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <!-- Error Message -->
    <div *ngIf="requestError">
      <p style="text-align: center; margin: 20px 0"><b>{{ requestError }}.</b></p>
      <p style="text-align: center">Please try refreshing the page.</p>
    </div>

    <!-- Dataset Table -->
    <app-table *ngIf="ELEMENT_DATA && !requestError" [tableData]="ELEMENT_DATA"></app-table>
  </mat-card>
</mat-card>

<mat-card id="component-container" *ngIf="pubmedID">
  <!-- PubMed View -->
  <mat-card id="title-container">
    <button mat-icon-button (click)="onBackClick()" id="back-button">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div id="title-container-inner">
      <h1 id="title">Pubmed ID</h1>
    </div>
  </mat-card>

  <mat-card id="title-container" style="margin-top: 10px;">
    <!-- Loading State -->
    <div *ngIf="!PUBMED_DATA && !requestError">
      <p style="text-align: center; margin: 20px 0">Fetching summary data...</p>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <!-- Error Message -->
    <div *ngIf="requestError">
      <p style="text-align: center; margin: 20px 0"><b>{{ requestError }}.</b></p>
      <p style="text-align: center">Please try refreshing the page.</p>
    </div>

    <!-- PubMed Data Table -->
    <app-table *ngIf="PUBMED_DATA && !requestError" [tableData]="PUBMED_DATA"></app-table>
  </mat-card>
</mat-card>
<br>
