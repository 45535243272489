import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-message-ai',
  templateUrl: './message-ai.component.html',
  styleUrls: ['./message-ai.component.css']
})
export class MessageAiComponent implements OnInit {
  messageContent: string = ''; // Store the API response
  protein1: string = ''; // User-provided Protein1
  protein2: string = ''; // User-provided Protein2
  species: string = ''; // User-provided Species
  selectedQuestion: string = ''; // Selected predefined question
  predefinedQuestions = [
    "The interaction between {Protein1} and {Protein2} in {Species}.",
    "If {Protein1} and {Protein2} are phosphorylated in {Species}?",
    "The kinase activity involving {Protein1} and {Protein2} in {Species}."
  ]; // Predefined questions

  constructor(private httpClient: HttpClient) {}

  ngOnInit(): void {}

  // Submit the protein names and species with the selected question
  onSubmit(): void {
    if (!this.protein1.trim() || !this.protein2.trim() || !this.species.trim()) {
      this.messageContent = "Please enter valid inputs for Protein1, Protein2, and Species.";
      console.log("Inputs are incomplete.");
      return;
    }

    if (!this.selectedQuestion) {
      this.messageContent = "Please select a predefined question.";
      console.log("No predefined question selected.");
      return;
    }

    // Construct the full question with the inputs
    const question = this.selectedQuestion
      .replace("{Protein1}", this.protein1.trim())
      .replace("{Protein2}", this.protein2.trim())
      .replace("{Species}", this.species.trim());
    console.log("Constructed question:", question);

    this.getMessageData(question);
  }

  // Fetch message data from the API
  getMessageData(question: string): void {
    const encodedQuestion = encodeURIComponent(question);
    const apiUrl = `${environment.BASE_API_URL}chatgpt/?content=${encodedQuestion}`;

    console.log("API URL:", apiUrl); // Log the full API URL

    forkJoin([
      this.httpClient.get(apiUrl)
    ]).subscribe({
      next: (result: any) => {
        console.log("Raw API response:", result);

        const apiResponse = result[0];

        // Check for the actual data structure
        if (apiResponse?.data) {
          this.messageContent = apiResponse.data; // Directly use the `data` property
          console.log("Extracted message content:", this.messageContent);
        } else {
          this.messageContent = "No valid response received.";
          console.warn("Unexpected API response format:", apiResponse);
        }
      },
      error: (err) => {
        console.error("Error fetching AI message:", err);
        this.messageContent = "Failed to fetch response. Please try again later.";
      }
    });
  }
}
