import { Component, OnInit } from '@angular/core';
import { forkJoin, iif, range } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as Papa from 'papaparse';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Required for animations
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-batch-search',
  templateUrl: './batch-search.component.html',
  styleUrls: ['./batch-search.component.css']
})
export class BatchSearchComponent implements OnInit {
  currentSelectedIds = [];
  listOfDataPeptides = [];
  phosphorylation_sites=[];
  id_sequence: String = "";
  idType: string = "LocusID";
  listOfDataProteinIDs = [];
  particularProteinData = [];
  mergedString: string = "";
  columnKeysProteinIDs = {
    'P3DB ID': { "val": 'p3db_id', "tooltip": "p3db ID" },
    'Protein Name': { "val": 'ProteinName', "tooltip": "protein Name" },
    'Species Name': { "val": 'Species Name', "tooltip": "species name" },
    'Uniprot ID': { "val": 'UniprotID', "tooltip": "uniprot ID" },
    'UniParc ID': { "val": 'UniParcID', "tooltip": 'uni parc ID' },
    RefSeq: { "val": 'RefSeq', "tooltip": "ref seq" },
    'Locus ID': { "val": 'LocusID', "tooltip": "locus Id" },
    'Gene Symbol': { "val": 'GeneSymbol', "tooltip": "gene symbol" },
    'Gene ID': { "val": 'GeneID', "tooltip": "Gene ID" },
    EnsemblPlants: { "val": 'EnsemblPlants', "tooltip": "emsembl plants" },
    EMBL: { "val": 'EMBL', "tooltip": "embl" },
    STRING: { "val": 'STRING', "tooltip": "string" },
    'BioGR ID': { "val": 'BioGRID', "tooltip": "bio grid" },
    IntAct: { "val": 'IntAct', "tooltip": "intact" },
    'Species ID': { "val": 'SpeciesID', "tooltip": "specied id" }
  };
  showWait = false;
  finalString: string = '';
  defaultValues: { [key: string]: string } = {
    'LocusID': "AT3G15060,AT1G06270,AT3G55010,AT1G01670,AT4G03000",
    'UniprotID': "Q9LNC0,Q05728,Q8GXQ7,Q9LK99,Q0WPJ7", // Example default values for UniprotID
    'UniParcID': "UPI000005EAC8,UPI00000017F8,UPI00000ADE8A,UPI000000C6AE,UPI000034F0E4",
    'BioGRID':"22379,9983",
    'p3db_id':"1740632,1740636,1740638,1740651,1740655" // Example default values for UniParcID
    
  };
  constructor(private http: HttpClient, private snackBar: MatSnackBar,private router: Router ) { }

  ngOnInit(): void {
    this.id_sequence = this.defaultValues[this.idType];
  }
  onIdTypeChange(event: MatSelectChange): void {
    const selectedIdType = event.value;
    console.log("idtype is : ", this.idType);
    this.idType = selectedIdType;
    this.id_sequence = this.defaultValues[selectedIdType] || ""; // Update the default values or set to empty if not defined
  }

  fetchJson(filename) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };

    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      })
    );
  }

  getData() {
    this.showWait = true;
    this.listOfDataProteinIDs = [];
    let splitArray = this.id_sequence.split(",")
    if (splitArray.length <=10 ){
      this.id_sequence = splitArray.map(str => str.trim()).join(',');
      console.log("the input sequence is : ", this.id_sequence)
      this.fetchJson(
        environment.BASE_API_URL + 'get_records_by_keyvalues/?key=' + this.idType + '&values=' + this.id_sequence
      ).subscribe((result) => {
        console.log(environment.BASE_API_URL + 'get_records_by_keyvalues/?key=' + this.idType + '&values=' + this.id_sequence);
        console.log("result is : ", result);
        let data = result['data'];


        this.listOfDataProteinIDs = data;
        let i = 0;
        this.listOfDataProteinIDs.forEach((item) => {
          i = i + 1
          item.isPresentinDatabase = item['Phosphorylation Protein(Yes/No)']
          item.batchSearch = 1;
          item.isSelected = false;
        });
        this.showWait = false;
        console.log("list of protein Id's : ", this.listOfDataProteinIDs);
    });

    }else{
      window.alert("Please limit the proteins to 10");
      this.showWait = false;
    }
    
  }

  handleProteinIds(proteinIds) {
    console.log("the protein ids are : ", proteinIds)
    this.currentSelectedIds = proteinIds;
  }

  handleP3DBID(obj){
   
      this.router.navigate(["/p3dbid_"+obj.val]);
    
        console.log("value is : ",obj);
  }

  getDataByProtein(val) {
    
    this.showWait = true;
    forkJoin([
      this.fetchJson(
        environment.BASE_API_URL +
          'get_intproteinid_details/?int_prot_id=' +
          val 
      ),
    ]).subscribe((result) => {
      console.log(environment.BASE_API_URL +
        'get_intproteinid_details/?int_prot_id=' +
        val);
      let data = result[0]['data'];
      let locusID = data['protein_detail']['fasta_header'].split("|")[0].trim().split(".")[0];
      
      
      this.dealInfo(
        data.peptides,
        data.peptide_features,
        data.paper_info,
        data.protein_detail
      );
      let sites = [];
      data.protein_detail.ptm_sites.forEach((element) => {
        sites.push(element.site_in_protein);
      });
      console.log("sites : ",sites);
      this.dealPtmSites(
        sites,
        data.protein_detail.sequence,
        data.protein_detail.internal_protein_id
      );
      this.getProteinID(locusID);
      this.showWait = false;
    });
  }

  dealInfo(peptides, features, paperInfo, protein_detail) {
    this.listOfDataPeptides=[]
    for (let item in peptides) {
      let internalProteinId = peptides[item].internal_protein_id;
      let feature = '';
      if(features[item]==null){
        feature = "N/A "+'\n';
      }else{
        features[item].forEach((item) => {
          feature += item.feature_name + ' : ' + item.feature_value + '\n';
        });
      }
      
      console.log("ads : ",protein_detail )
      let hasArray = false;
      for (const key in protein_detail) {
        if (Array.isArray(protein_detail[key])) {
          hasArray = true;
        }
        break;
      }

      if(Object.keys(protein_detail).length === 0){
        peptides[item].fastaHeader = "N/A";
      peptides[item].highlightProteinName = "false";
      peptides[item].peptide_feature = feature;
      peptides[item].description = 'Protein Details:' + '\n';
      peptides[item].description +=
        'Fasta Header: ' + "N/A " + '\n';
      peptides[item].description +=
        'External Id Type: ' + "N/A " + '\n';
      peptides[item].description += 'ID: ' + " N/A" + '\n';
      peptides[item].description += 'Length: ' + " N/A" + '\n';
      peptides[item].description +=
        'Organism Id: ' + " N/A" + '\n' + '\n';
      peptides[item].description += "Paper's Information:" + '\n';
      if (paperInfo[peptides[item].datasource]) {
        peptides[item].description +=
            'Title:' + (paperInfo[peptides[item].datasource].title || "N/A") + '\n';
        peptides[item].description +=
            'Link:' + (paperInfo[peptides[item].datasource].link || "N/A") + '\n';
        peptides[item].description +=
            'Year:' + (paperInfo[peptides[item].datasource].year || "N/A") + '\n';
    } else {
        peptides[item].description += 'Title: N/A\n';
        peptides[item].description += 'Link: N/A\n';
        peptides[item].description += 'Year: N/A\n';
    }
      peptides[item].id = peptides[item].internal_peptide_id;
      this.listOfDataPeptides.push(peptides[item]);
      }else{
        
        if(hasArray){
          
          let fasta_header = protein_detail[peptides[item].internal_protein_id][0].fasta_header;
  
          if (fasta_header.includes('|')) {
              fasta_header = fasta_header.split("|")[0];
          }      
        peptides[item].fastaHeader = fasta_header;
        peptides[item].highlightProteinName = "false";
        peptides[item].peptide_feature = feature;
        peptides[item].description = 'Protein Details:' + '\n';
        peptides[item].description +=
          'Fasta Header: ' + protein_detail[internalProteinId][0].fasta_header + '\n';
        peptides[item].description +=
          'External Id Type: ' + protein_detail[internalProteinId][0].external_id_type + '\n';
        peptides[item].description += 'ID: ' + protein_detail[internalProteinId][0].id + '\n';
        peptides[item].description += 'Length: ' + protein_detail[internalProteinId][0].length + '\n';
        peptides[item].description +=
          'Organism Id: ' + protein_detail[internalProteinId][0].organism_id + '\n' + '\n';
        peptides[item].description += "Paper's Information:" + '\n';
        peptides[item].description +=
          'Title:' + paperInfo[peptides[item].datasource].title + '\n';
        peptides[item].description +=
          'Link:' + paperInfo[peptides[item].datasource].link + '\n';
        peptides[item].description +=
          'Year:' + paperInfo[peptides[item].datasource].year + '\n';
        peptides[item].id = peptides[item].internal_peptide_id;
        this.listOfDataPeptides.push(peptides[item]);
        }
        else{
          let fasta_header = protein_detail.fasta_header;
  
          if (fasta_header.includes('|')) {
              fasta_header = fasta_header.split("|")[0];
          } 
        peptides[item].fastaHeader = fasta_header;
        
          peptides[item].peptide_feature = feature;
        peptides[item].description = 'Protein Details:' + '\n';
        peptides[item].description +=
          'Fasta Header: ' + protein_detail.fasta_header + '\n';
        peptides[item].description +=
          'External Id Type: ' + protein_detail.external_id_type + '\n';
        peptides[item].description += 'ID: ' + protein_detail.id + '\n';
        peptides[item].description += 'Length: ' + protein_detail.length + '\n';
        peptides[item].description +=
          'Organism Id: ' + protein_detail.organism_id + '\n' + '\n';
        peptides[item].description += "Paper's Information:" + '\n';
        peptides[item].description +=
          'Title:' + paperInfo[peptides[item].datasource].title + '\n';
        peptides[item].description +=
          'Link:' + paperInfo[peptides[item].datasource].link + '\n';
        peptides[item].description +=
          'Year:' + paperInfo[peptides[item].datasource].year + '\n';
        peptides[item].id = peptides[item].internal_peptide_id;
        this.listOfDataPeptides.push(peptides[item]);
        }
      }

      
      
      console.log("List of Data Peptides : ",this.listOfDataPeptides);
    }
  }

  dealPtmSites(sties, sequence, id) {
    let phosphorylation_sites = [];
    this.phosphorylation_sites = []
    let ptm_index = 0;
    let index_highLight = sties.map((element) => {
      return element - 1;
    });
    let array_seq = [...sequence];
    array_seq.forEach((element, index) => {
      if (index_highLight.includes(index)) {
        phosphorylation_sites.push({
          name: element,
          class: 'highlight',
          index: '',
          arrow: '',
          tooptip: '',
          protien_id:id
        });
        ptm_index++;
      } else {
        phosphorylation_sites.push({
          name: element,
          class: 'none',
          index: (index + 1) % 10 == 0 ? String(index + 1) : '',
          arrow: (index + 1) % 10 == 0 ? '|' : '',
          tooptip: '',
          protien_id:id
        });
      }
    });
    // this.proteinId.push(id);
    this.phosphorylation_sites.push(phosphorylation_sites);
    console.log("List of Phosphorylation sites : ",this.phosphorylation_sites);
  }


  getProteinID(val) {
    //https://p3db.ddns.net:5000/api/mapping/?LocusID=AT1G01&regex=True
    this.showWait = true;
    this.listOfDataProteinIDs=[];
    
    forkJoin([
      this.fetchJson(
        environment.BASE_API_URL + 'mapping/?'+this.idType+'=' + val + '&regex=True'
      ),
    ]).subscribe((result) => {
      let data = result[0]['data'];
      console.log("Api is : ",environment.BASE_API_URL + 'mapping/?'+this.idType+'=' + val + '&regex=True');
      // console.log("Data is  : ",data);
      this.particularProteinData = data;
      let i=0;
      this.particularProteinData.forEach((item) => {
        i=i+1
        item.icon = item['Phosphorylation Protein(Yes/No)'];
      });
      
      this.showWait = false;
    
      
      console.log("list of protein Id's : ",this.particularProteinData);
    });
  }

  downloadData() {
/*
    console.log("current selected id's are : ", this.currentSelectedIds);
    this.mergedString = this.currentSelectedIds.join(",");
    console.log("result is : ", this.mergedString);
    this.fetchJson(
      environment.BASE_API_URL + 'get_multiple_intproteinid_details/?prot_id_list=' + this.mergedString
    ).subscribe((result) => {
      console.log(environment.BASE_API_URL + 'get_multiple_intproteinid_details/?prot_id_list=' + this.mergedString);
      let data = result['data'];

      Object.keys(data).forEach(key => {
        let myObject = {}
        if (data[key].message == "success") {
          myObject['proteinId'] = key.split("_")[1];
          this.getApiData(
            data[key].peptides,
            data[key].peptide_features,
            data[key].paper_info,
            data[key].protein_detail,
            myObject
          );
          this.listOfDataPeptides.push(myObject);
        }


      });
      console.log("list of data peptides : ", this.listOfDataPeptides);
      const csvData = JSON.parse(JSON.stringify(this.listOfDataPeptides));
      this.downloadCSV(csvData);
    });*/

  /*
  const csvData = JSON.parse(JSON.stringify(this.listOfDataProteinIDs));
  this.downloadCSV(csvData);*/

    let data = this.listOfDataProteinIDs;

    Object.keys(data).forEach(key => {
      let myObject = {}
      if(this.currentSelectedIds.includes(data[key].p3db_id)){
        myObject['BioGRID']=data[key].BioGRID;
        myObject['EMBL']=data[key].EMBL;
        myObject['EnsemblPlants']=data[key].EnsemblPlants;
        myObject['GeneID']=data[key].GeneID;
        myObject['GeneSymbol']=data[key].GeneSymbol;
        myObject['IntAct']=data[key].IntAct;
        myObject['LocusID']=data[key].LocusID;
        myObject['Phosphorylation Protein(Yes/No)']=data[key]['Phosphorylation Protein(Yes/No)'];
        myObject['ProteinName']=data[key].ProteinName;
        myObject['RefSeq']=data[key].RefSeq;
        myObject['STRING']=data[key].STRING;
        myObject['Species Name']=data[key]['Species Name'];
        myObject['SpeciesID']=data[key].SpeciesID;
        myObject['UniParcID']=data[key].UniParcID;
        myObject['UniprotID']=data[key].UniprotID;
        myObject['input_key']=data[key].input_key;
        myObject['input_value']=data[key].input_value;
        myObject['p3db_id']=data[key].p3db_id;
        this.listOfDataPeptides.push(myObject);
      }
    });
    console.log("list of data peptides : ", this.listOfDataPeptides);
    const csvData = JSON.parse(JSON.stringify(this.listOfDataPeptides));
    this.downloadCSV(csvData);
  }

  getApiData(peptides, features, paper_info, protein_detail, myObject) {

    let first_key = Object.keys(peptides)[0]
    myObject["datasource"] = peptides[first_key].datasource;
    myObject["peptide"] = peptides[first_key].peptide;
    myObject['peptide_in_protein'] = peptides[first_key].peptide_in_protein;
    myObject['site_in_peptide'] = peptides[first_key].site_in_peptide;
    if (Object.keys(protein_detail).length === 0) {
      myObject['fastaHeader'] = "N/A";

      myObject['description'] = 'Protein Details:' + '\n';
      myObject['description'] += 'Fasta Header: ' + "N/A " + '\n';
      myObject['description'] += 'External Id Type: ' + "N/A " + '\n';
      myObject['description'] += 'ID: ' + " N/A" + '\n';
      myObject['description'] += 'Length: ' + " N/A" + '\n';
      myObject['description'] += 'Organism Id: ' + " N/A" + '\n' + '\n';
      myObject['description'] += "Paper's Information:" + '\n';
    } else {
      let fasta_header = protein_detail.fasta_header;

      if (fasta_header.includes('|')) {
        fasta_header = fasta_header.split("|")[0];
      }
      myObject['fastaHeader'] = fasta_header

      myObject['description'] = 'Protein Details:' + '\n';
      myObject['description'] += 'Fasta Header: ' + protein_detail.fasta_header + '\n';
      myObject['description'] += 'External Id Type: ' + protein_detail.external_id_type + '\n';
      myObject['description'] += 'ID: ' + protein_detail.id + '\n';
      myObject['description'] += 'Length: ' + protein_detail.length + '\n';
      myObject['description'] += 'Organism Id: ' + protein_detail.organism_id + '\n' + '\n';
      myObject['description'] += "Paper's Information:" + '\n';
    }

    if (paper_info == null || paper_info.hasOwnProperty(peptides[first_key].datasource) == false) {
      myObject['description'] += "Title: " + "N/A" + "\n";
      myObject['description'] += "Link: " + "N/A" + "\n";
      myObject['description'] += "Year: " + "N/A" + "\n";
    } else {
      myObject['description'] += "Title: " + paper_info[peptides[first_key].datasource].title + "\n";
      myObject['description'] += "Link: " + paper_info[peptides[first_key].datasource].link + "\n";
      myObject['description'] += "Year: " + paper_info[peptides[first_key].datasource].year + "\n";
    }
    myObject['feature'] = "";
    if (features == null || features.hasOwnProperty(first_key) == false) {
      myObject['feature'] += "N/A" + "\n";
    } else {
      features[first_key].forEach((item) => {
        myObject['feature'] += item.feature_name + ' : ' + item.feature_value + '\n';
      });
    }
  }

  downloadCSV(data: any): void {


    const csvData = this.convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  convertToCSV(data: any) {
    const csv = Papa.unparse(data);
    return csv;
  }

}
