<mat-card id="message-container">
  <!-- Header -->
  <mat-card>
    <h1 id="title" style="text-align: center;"><b>AI Q&A</b></h1>
  </mat-card>

  <!-- Instruction Section -->
  <mat-card style="margin-top: 10px; background-color: #f9f9f9; padding: 15px; border-radius: 8px;">
    <p style="font-size: 16px; margin-bottom: 15px; text-align: center; color: #333; line-height: 1.6;">
      <strong style="font-size: 18px; color: #2c3e50;">
        Enter three protein names below to utilize our optimized AI prompts.
      </strong><br/>
      For more information about how these prompts perform, check out:
      <a href="#" target="_blank" style="color: #007bff; text-decoration: underline;">
        Prompt Performance Details
      </a>.
    </p>
  </mat-card>

  <!-- Input Section -->
  <mat-card style="margin-top: 10px;">
    <!-- Inputs in One Line -->
    <div style="display: flex; gap: 10px;">
      <!-- Protein1 Input -->
      <nz-input-group nzSearch nzSize="large" style="flex: 1;">
        <input
          type="text"
          [(ngModel)]="protein1"
          nz-input
          placeholder="Enter Protein1"
          id="protein1-input" />
      </nz-input-group>

      <!-- Protein2 Input -->
      <nz-input-group nzSearch nzSize="large" style="flex: 1;">
        <input
          type="text"
          [(ngModel)]="protein2"
          nz-input
          placeholder="Enter Protein2"
          id="protein2-input" />
      </nz-input-group>

      <!-- Species Input -->
      <nz-input-group nzSearch nzSize="large" style="flex: 1;">
        <input
          type="text"
          [(ngModel)]="species"
          nz-input
          placeholder="Enter Species"
          id="species-input" />
      </nz-input-group>
    </div>
    <br/>

    <!-- Predefined Questions Dropdown -->
    <mat-form-field style="width: 100%;" appearance="outline">
      <mat-label>Select a predefined question</mat-label>
      <mat-select [(value)]="selectedQuestion">
        <mat-option
          *ngFor="let question of predefinedQuestions"
          [value]="question">
          {{ question }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br/>

    <!-- Submit Button -->
    <button
      mat-raised-button
      color="primary"
      (click)="onSubmit()"
      style="width: 100%;"
    >
      Submit
    </button>
  </mat-card>

  <!-- Answer Section -->
  <mat-card style="margin-top: 10px;">
    <h2>Answer is:</h2>
    <div
      class="information"
      style="overflow-x: scroll; word-wrap: break-word; white-space: pre-wrap;"
    >
      {{ messageContent }}
    </div>
  </mat-card>
</mat-card>
