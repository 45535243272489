import { CdkHeaderRowDef } from '@angular/cdk/table';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgZone } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { environment } from 'src/environments/environment';
declare var logojs: any;

@Component({
  selector: 'app-protein-logo',
  templateUrl: './protein-logo.component.html',
  styleUrls: ['./protein-logo.component.scss']
})
export class ProteinLogoComponent implements OnInit {

  constructor(private http: HttpClient, private sharedService: SharedService, private ngZone: NgZone) { }
  ELEMENT_DATA;
  requestError: string;
  PROTEIN_PPM = [];
  Motif_files_list = [];
  Motiffiles = new FormControl();
  errorMessage: boolean = false;
  organisim_sequence: string = 'STIGVEFATR,STIGVEFAGR,TTIGVEFATR,STIGVEFSTR'; // Default input value
  showLogo: boolean = false;

  ngOnInit(): void {
    // Ensure the shared service doesn't overwrite the default unless triggered
    this.sharedService.selectedText$.subscribe((selectedText: string) => {
      if (selectedText) {
        this.organisim_sequence = selectedText;
      }
    });
  }

  transpose(matrix: number[][]): number[][] {
    return matrix[0].map((_, i) => matrix.map(row => row[i]));
  }

  getData() {
    let formData = { sequences: this.organisim_sequence.split(',') };
    console.log('Form Data:', JSON.stringify(formData));
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');

    this.http
      .post<any>(environment.BASE_API_URL + 'getfastapwm/', JSON.stringify(formData), {
        headers: headers,
      }).subscribe((result) => {
        console.log('API Result:', result);
        let res: any = Object.values(result['data']);
        console.log('Processed Data:', res);
        this.PROTEIN_PPM = result['data'];
        if (res.length === 0) {
          this.errorMessage = true;
          const elem = document.getElementById("logo");
          if (elem) {
            elem.innerHTML = "";
          }
        } else {
          let alphabets = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
          let prot_ppm = {};

          alphabets.forEach(a => {
            if (!this.PROTEIN_PPM[a]) {
              if (!['O', 'J', 'U', 'X'].includes(a)) {
                prot_ppm[a] = new Array(res[0].length).fill(0.0);
              }
            } else {
              prot_ppm[a] = this.PROTEIN_PPM[a];
            }
          });

          console.log('Processed PPM:', prot_ppm);
          this.ngZone.run(() => {
            this.errorMessage = false;
            this.PROTEIN_PPM = this.transpose(Object.values(prot_ppm));
            console.log('Transposed PPM:', this.PROTEIN_PPM);
            this.showLogo = true;
            setTimeout(() => {
              logojs.embedProteinLogo(document.getElementById("logo"), { ppm: this.PROTEIN_PPM });
            }, 0);
          });
        }
      }, error => {
        console.log('API Error:', error);
      });
  }
}
