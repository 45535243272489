<mat-card id="upload-container" style="margin-top: 10px;">
  <mat-card>
    <h1 id="title" style="text-align: center;"><b>AI Gene - Detection</b></h1>
  </mat-card> 
  <mat-card style="margin-top: 10px;">
    <div class="Choosefile-container">
      <label for="formFile" class="file-label">Upload Image to GPT</label>
      <input (change)="onFilechange($event)" class="file-control" type="file" id="formFile">
      <button (click)="upload()" type="button" class="upload-button">Analyze</button>
      <button (click)="reset()" type="button" class="upload-button">Reset</button>
    </div>
  </mat-card>

  <mat-card style="margin-top: 10px;">
    <p style="text-align: justify; margin: 10px; font-size: 14px;">
      <b>Instruction:</b> The gene relationships can be detected using a well-tuned ChatGPT prompt.
      For detailed information on the experimental setup and testing of the prompt, please refer to the
      corresponding documentation or guidelines.
    </p>
  </mat-card>

  <!-- Displaying Table -->
  <mat-card style="margin-top: 10px;" *ngIf="isFileUploaded">

    <div *ngIf="!ELEMENT_DATA" class="loading_text">
      <p> Processing image...</p>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <mat-card-header *ngIf="loadingImage == false">
      <mat-card-title class="file-label">Uploaded Pathway</mat-card-title>
    </mat-card-header>
    <div *ngIf="isFileUploaded && loadingImage == false">
      <img *ngIf="imageSrc" [src]="imageSrc" alt="Uploaded Image" style="max-width: 100%; height: auto;" />
      <p *ngIf="imageError">{{ imageError }}</p>
    </div>

    <mat-card-header *ngIf="loadingImage == false">
      <mat-card-title class="file-label">Results</mat-card-title>
    </mat-card-header>

    <div *ngIf="loadingImage == false">
      <app-table [tableData]="ELEMENT_DATA"></app-table>
    </div>

  </mat-card>

</mat-card>
