
<mat-card id="component-container" style="padding-bottom: 1px;">
    
    <mat-card>
        <div>
            <!--<div style="float: left;">
                <span class="input-option" [class.selected]="!blastSelected" (click)="blastSelected = false">Sequence</span>
                <mat-slide-toggle aria-label="input-selector" color="primary" [(ngModel)]="blastSelected">
                </mat-slide-toggle>
                <span class="input-option" [class.selected]="blastSelected" (click)="blastSelected = true">Uniprot</span>
                
            </div>-->
            <h2 style="text-align: center; ">3D Structure Viewer</h2>
        </div>
    </mat-card>
    <!--<div *ngIf="!blastSelected">
        <mat-card class="card">
            <mat-form-field class="input_sequence">
                <mat-label class ="input_sequence_label" >Sequence</mat-label>
                <textarea id = "seq" matInput placeholder="MERSPAVCCQDPRAELVERVAAISVAHLEEAEEGPEPASNGVDPPPRARAASVIPGSASRPTPVRPSLSARKFSLQERPAGSCLEAQVGPYSTG"></textarea>
            </mat-form-field>

            <mat-form-field class="input_sequence">
                <mat-label class ="input_sequence_label" >Position</mat-label>
                <textarea id = "position" matInput placeholder="4,10,14"></textarea>
            </mat-form-field>

            <button mat-flat-button  class="predict_seq" (click) = "fn_predict()">
                Predict
            </button>
            <span (click)="fn_example()" style="color: red; margin-left: 10px;">Load example</span>
        </mat-card>
    </div>-->
    <div *ngIf="blastSelected">
        <mat-card class="card">
            <mat-form-field class="input_sequence">
                <mat-label class ="input_sequence_label" > Uniprot ID</mat-label>
                <textarea id = "uniprot" matInput placeholder="Q9LK99"></textarea>
            </mat-form-field>
            <button mat-flat-button  class="predict_seq" (click) = "fn_uniprot()">
                Predict
            </button>
            <span (click)="fn_loadUniprot()" style="color: red; margin-left: 10px;">Load example</span>
        </mat-card>

        <mat-card class="card" *ngIf="showCard">
            <div *ngIf="noPdb && !g2sLoading" class="centered">No PDB matches found.</div>
            <mat-progress-bar mode="indeterminate" *ngIf="g2sLoading"></mat-progress-bar>
            <mat-table [dataSource]='G2SDataSource' id='table' *ngIf="!g2sLoading">
                <ng-container matColumnDef="entryId">
                    <mat-header-cell *matHeaderCellDef >Entry ID</mat-header-cell>
                    <mat-cell *matCellDef="let elem"> {{elem.entryId}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="gene">
                    <mat-header-cell *matHeaderCellDef >Gene</mat-header-cell>
                    <mat-cell *matCellDef="let elem"> {{elem.gene}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="uniprotAccession">
                    <mat-header-cell *matHeaderCellDef >Accession</mat-header-cell>
                    <mat-cell *matCellDef="let elem"> {{elem.uniprotAccession}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="taxId">
                    <mat-header-cell *matHeaderCellDef >Taxonomy ID</mat-header-cell>
                    <mat-cell *matCellDef="let elem"> {{elem.taxId}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="uniprotStart">
                    <mat-header-cell *matHeaderCellDef >Uniprot Start</mat-header-cell>
                    <mat-cell *matCellDef="let elem"> {{elem.uniprotStart}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="uniprotEnd">
                    <mat-header-cell *matHeaderCellDef >Uniprot End</mat-header-cell>
                    <mat-cell *matCellDef="let elem"> {{elem.uniprotEnd}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="uniprotDescription">
                    <mat-header-cell *matHeaderCellDef >Uniprot Description</mat-header-cell>
                    <mat-cell *matCellDef="let elem"> {{elem.uniprotDescription}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="organismScientificName">
                    <mat-header-cell *matHeaderCellDef >Scientific Name</mat-header-cell>
                    <mat-cell *matCellDef="let elem"> {{elem.organismScientificName}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="3DViewer">
                    <mat-header-cell *matHeaderCellDef >3D Viewer </mat-header-cell>
                    <mat-cell *matCellDef="let elem">
                        <button mat-raised-button color="primary" class="viewer-button"
                            (click)="showViewer(elem.entryId, elem.pdbUrl)">
                            <mat-icon>launch</mat-icon>
                        </button>
                        <a mat-button href="/assets/display.html?pdbId={{elem.pdbUrl}}"
                            target="_blank">
                            <mat-icon>exit_to_app</mat-icon>
                        </a>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="tableColumns; sticky: true;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: tableColumns;"></mat-row>
            </mat-table>
        </mat-card>
        
    </div>
    
</mat-card>
