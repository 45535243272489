<mat-card id="component-container">
  <mat-card>
    <h2 id="title">Ortholog Conservation</h2>
  </mat-card>

  <mat-card id="title-container" style="margin-top: 10px; overflow-x: scroll;">
    <pre style="overflow-x: hidden;">
      <strong>Note:</strong>
      Name: Index number
      Uniprot Orthogs: In Soybean/Rice/Medicago/Zeamays/Lotus Orthogs protein in other species with the protein ID
      Matched IDs: Arabidopsis locus ID that contains this peptide sequence
      Conservation value: Sequence similarity calculated as described in the methods
      Conserved phosphorylation count: Number of protein sequences in the multiple sequence alignment that possess conserved peptides
      Conserved Phosites: Location within the multiple sequence alignment with phosphorylation on a peptide from a species besides Arabidopsis
      New location on MSA: Results of location within the multiple sequence alignment

      <strong>Source Explanations:</strong>
      Source 1: Phosphopeptides conserved across species
      Source 2: Potentially conserved phosphopeptides
      Source 3: Predicted P2Ks phosphopeptides
      Source 4: Rice cell wall and development
      Source 5: Root expression
    </pre>

    <!-- Show loading indicator while fetching data -->
    <div *ngIf="loading" class="loading-text">
      <p style="text-align: center; margin: 20px 0">Fetching orthology data...</p>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <!-- Show table once data is loaded -->
    <app-table *ngIf="ELEMENT_DATA" [tableData]="ELEMENT_DATA"></app-table>
  </mat-card>
</mat-card>
