import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() tableData;
  displayedColumns;
  dataSource;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };

  constructor(private clipboard: Clipboard) {}

  ngOnInit(): void {
    this.displayedColumns = this.tableData['name'];
    this.dataSource = new MatTableDataSource(this.tableData['data']);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
      // console.log(this.tableData)
      // console.log(this.dataSource)

  }

  ngOnChanges() {
    // console.log(this.tableData)
    this.displayedColumns = this.tableData['name'];
    this.dataSource = new MatTableDataSource(this.tableData['data']);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.tableData['data']);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  condenseText(text: String) {
    if (text.length <= 10) {
      return text; 
    } else {
      return text.substring(0, 25) + "...";
    }  }

    // shortens the cell and then gives an option for copying the text
  groupsToBeCondensed1(text: String) {
    if (text == "gen_seq" || text == "protein_seq")
      {
        return true;
      }
    else {
      return false;
    }
  }

   // shortens the cell and then gives an option to hover to show full text
  groupsToBeCondensed2(text: String) {
    if (text == "Title")
      {
        return true;
      }
    else {
      return false;
    }
  }


  groupsToBeHyperlinked(text: String) {
    if (text == "experimental_study" || text == "p3db_id" || text == "PubmedID")
      {
        return true;
      }
    else {
      return false;
    }
  }

  groupsToBeRouterLinked(text: String, element?: any) {
    if (text == "internal_protein_id_count")
      {
        if(element == 0)
          {
            return false;
          }
        else {
          return true;
        }
      }
    else {
      return false;
    }
  }

  copyText(text: string) {
    this.clipboard.copy(text);
  }

}
