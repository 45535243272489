import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { forkJoin, queueScheduler } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { any } from '@amcharts/amcharts5/.internal/core/util/Array';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as Papa from 'papaparse';

@Component({
  selector: 'app-chat-gpt-image-upload',
  templateUrl: './chat-gpt-image-upload.component.html',
  styleUrls: ['./chat-gpt-image-upload.component.css']
})
export class ChatGPTImageUploadComponent implements OnInit {
  inputvalue: any;
  file: File = null;
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>([]);
  defaultPageSize = 5;
  pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isFileUploaded = false;
  filesObj = null;  
  loadingImage = null;
  imageError = "";
  ELEMENT_DATA;
  imageSrc: string | ArrayBuffer | null = null; 

  constructor(private httpClient:HttpClient) {}

  ngOnInit(): void {
  }

  onFilechange(event: any) {
    this.filesObj = event.target.files;
    this.file = event.target.files[0].name;
  }

  upload() {
    this.loadingImage = true;
    this.imageError = "";
    if (this.filesObj && this.filesObj[0]) {
      this.isFileUploaded=true;
      var imgReader = new FileReader();

      imgReader.readAsDataURL(this.filesObj[0]); // read file as data url

      imgReader.onload = (event) => { // called once readAsDataURL is completed
        this.imageSrc = imgReader.result; // Store the Data URL



        
      let formData = new FormData();
      formData.append("image", this.filesObj[0]);

      this.httpClient.post(environment.BASE_API_URL + "askchatgpt_with_image/", formData).subscribe((data:any) => {
        this.dealInfo(data.data);

        this.loadingImage = false;
      });
      }
      }else{
        this.imageError = "No image selected to upload";
        this.loadingImage = false;

      }
  }

  dealInfo(data){
    this.ELEMENT_DATA = {
      name: ["gene1", "relation","action", "gene2","phosphorylation"],
      display:["Gene1","Relation", "Action","Gene2","Phosphorylation"],
      data:data,
      
    }
  }
  reset(){
    this.isFileUploaded=false;
    this.inputvalue = document.getElementById('formFile') as HTMLInputElement;
    if (this.inputvalue) {
      this.inputvalue.value = '';
    }

  }

}
