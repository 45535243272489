import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import * as Papa from 'papaparse';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from "src/environments/environment";

interface Species {
  taxonomy_id: string;
  species_name: string;
  common_name: string;
}

@Component({
  selector: 'app-kinaseclient',
  templateUrl: './kinaseclient.component.html',
  styleUrls: ['./kinaseclient.component.css']
})
export class KinaseclientComponent implements OnInit {
  speciesList: Species[] = [];
  filteredSpecies!: Observable<Species[]>;
  searchControl = new FormControl('Arabidopsis thaliana'); // Default value
  selectedSpecies: Species = {
    taxonomy_id: '3702',
    species_name: 'Arabidopsis thaliana',
    common_name: 'Thale cress'
  }; // Default selected species
  ELEMENT_DATA: any = {
    name: [],
    display: [],
    data: []
  };

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.loadSpecies();

    this.filteredSpecies = this.searchControl.valueChanges.pipe(
      startWith(this.searchControl.value), // Start with the default value
      map(value => this.filterSpecies(value))
    );

    // Trigger initial fetch using the default selected species
    this.fetchSpeciesData(this.selectedSpecies.taxonomy_id);
  }

  loadSpecies(): void {
    const csvPath = 'assets/species.csv';
    this.http.get(csvPath, { responseType: 'text' }).subscribe(
      (data) => {
        Papa.parse(data, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            this.speciesList = result.data.map((row: any) => ({
              taxonomy_id: row['Taxonomy ID'],
              species_name: row['Species Name'],
              common_name: row['Common Name']
            }));
            console.log('Parsed Species List:', this.speciesList);
          },
          error: (err) => console.error('Error loading CSV:', err)
        });
      },
      (error) => console.error('Error fetching CSV file:', error)
    );
  }

  filterSpecies(query: any): Species[] {
    if (typeof query !== 'string') {
      query = '';
    }

    const lowercaseQuery = query.toLowerCase();
    return this.speciesList.filter(species =>
      species.taxonomy_id.toLowerCase().includes(lowercaseQuery) ||
      species.species_name.toLowerCase().includes(lowercaseQuery) ||
      species.common_name.toLowerCase().includes(lowercaseQuery)
    );
  }

  displaySpecies(species: Species | null): string {
    if (!species) return '';
    return `${species.taxonomy_id} - ${species.species_name} (${species.common_name})`;
  }

  onSpeciesSelect(species: Species): void {
    this.selectedSpecies = species; // Store the selected species object
    console.log('Selected Species:', this.selectedSpecies);

    this.fetchSpeciesData(species.taxonomy_id); // Fetch data using taxonomy_id
  }

  fetchSpeciesData(speciesTaxonomyId: string): void {
    const apiUrl = environment.BASE_API_URL + 'kinasefamily/?taxonomy_id=' + speciesTaxonomyId;
    console.log('Fetching data from:', apiUrl);
    const startTime = performance.now();
    this.http.get(apiUrl).subscribe(
      (response: any) => {
        const endTime = performance.now();
        console.log('API Response:', response);
        console.log('API Response Size:', JSON.stringify(response).length);
        console.log(`API Data Fetch and Processing: ${endTime - startTime} ms`);

        if (response && response.data) {
          this.ELEMENT_DATA = {
            name: ['id', 'kinasefamily', 'gen_seq', 'protein_seq', 'species_name'],
            display: [
              'ID',
              'Kinase Family',
              'Gene Sequence',
              'Protein Sequence',
              'Species Name'
            ],
            data: response.data.map((item: any) => ({
              id: item.id,
              kinasefamily: item.kinasefamily,
              gen_seq: item.gen_seq.substring(0, 50) + '...',
              protein_seq: item.protein_seq.substring(0, 50) + '...',
              species_name: item.species_name
            }))
          };
        } else {
          console.warn('Kinase API response did not contain "data".', response);
        }
      },
      (error) => console.error('Error fetching kinase data:', error)
    );
  }
}
