<nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
        <a href="/home">
            <img style="height: 4vh; margin-right: 2vh;" src="assets/images/p3dblogo_v1.png" />
        </a>
        <a class="navbar-brand" href="/home" style="text-decoration:none">
            <span class="logo_P3DB">P3DB</span>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav me-auto flex-grow-1">
                <li class="nav-item">
                    <a class="nav-link menu" href="/home">Home</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle menu" href="/browse" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-hover="dropdown">
            Browse
          </a>
                    <ul class="dropdown-menu my-custom-class" aria-labelledby="navbarDropdown1" style="max-height: calc(100vh - 56px);">
                        <!-- <li><a class="dropdown-item" href="/phosphoprotein-browse"> Phosphoprotein Data</a></li> -->
                        <li><a class="dropdown-item" href="/datasets">Datasets</a></li>
                        <li><a class="dropdown-item" href="/kinase">Kinase</a></li>
                        <li><a class="dropdown-item" href="/phylogenetic-tree">Organism</a></li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle menu" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Tools
          </a>
                    <ul class="dropdown-menu my-custom-class" aria-labelledby="navbarDropdown2" style="max-height: calc(100vh - 56px);">
                        <li><a class="dropdown-item" href="/ptmPeptides">PTM Data Search</a></li>
                        <li><a class="dropdown-item" href="/batchSearch">PTM Batch Search</a></li>
                        <li><a class="dropdown-item" href="/proteinblastsearch">PTM Blast Search</a></li>
                        <li><a class="dropdown-item" href="/kicnetwork">KiC Assay</a></li>
                        <li><a class="dropdown-item" href="/ptmPrediction">PTM Prediction</a></li>
                        <li><a class="dropdown-item" href="/show3D">3D Structure Viewer</a></li>
                        <li><a class="dropdown-item" href="/ask-ai">Ask AI</a></li>
                        <li><a class="dropdown-item" href="/motif">Kinase Substrate Motif</a></li>
                        <li><a class="dropdown-item" href="/organism-search">Organism Search</a></li>
                        <li><a class="dropdown-item" href="/pathway-viewer">Pathway Viewer</a></li>
                        <li><a class="dropdown-item" href="/ppiNetwork">PPI Network</a></li>
                        <li><a class="dropdown-item" href="/ProteinLogo">Peptide Logo</a></li>
                        <li><a class="dropdown-item" href="/PTMViewer">PTM Viewer</a></li>
                        <li><a class="dropdown-item" href="/UniversalSearch">ID Mapping</a></li>
                        <!-- <li><a class="dropdown-item" href="/ProteinLogo">Protein Logo</a></li> -->



                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle menu" id="navbarDropdown3" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-hover="dropdown">
            Conservation
          </a>
                    <ul class="dropdown-menu my-custom-class" aria-labelledby="navbarDropdown3" style="max-height: calc(100vh - 56px);">
                        <li><a class="dropdown-item" href="/othologConservation"> Ortholog Conservation</a></li>
                        <li><a class="dropdown-item" href="/ProteinConservationRegionSearch">Protein Conservation Region Search</a></li>
<!--                        <li><a class="dropdown-item" href="/PeptideConservationSearch">Peptide Conservation Search</a></li>-->
                    </ul>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle menu" id="navbarDropdown3" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-hover="dropdown">
            Information
          </a>
                    <ul class="dropdown-menu my-custom-class" aria-labelledby="navbarDropdown3" style="max-height: calc(100vh - 56px);">
                        <li><a class="dropdown-item" href="/cite">Cite</a></li>
                        <li><a class="dropdown-item" href="/contact">Contact</a></li>
                        <li><a class="dropdown-item" href="/team">Team</a></li>
<!--                        <li><a class="dropdown-item" href="/usermanual">Tutorial</a></li>-->
                    </ul>
                </li>
            </ul>
            <span class="navbar-text">
              <!-- <div id="mapmyvisitors" class="map">
                <a style="display: none;" href='https://mapmyvisitors.com/web/1bvpn' title='Visit tracker'><img src='https://mapmyvisitors.com/map.png?cl=ffffff&w=a&t=n&d=jBSGfAxOjmSQ7IKqiLuFT26u6AddCBn2dyzXVK4e3XY' /></a>
                <p> <span class="view_counter">{{view_count }} </span> <br>hits on p3db.org</p>
        </div> -->
        <!-- total hits -->
<!--        <div><img src="https://www.cutercounter.com/stats.php?id=hxpdokp&nd=6&style=9" border="0" alt="visitor counter"></div>-->
        <!-- unique visitors -->
        <!-- <div><img src="https://www.cutercounter.com/stats.php?id=hvxpdokq&nd=6&style=65" border="0" alt="hit counter"></div> -->
<!--        <i>hits on p3db.org</i>-->
        </span>
    </div>
    </div>
</nav>
