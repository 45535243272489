import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ortholog-conservation',
  templateUrl: './ortholog-conservation.component.html',
  styleUrls: ['./ortholog-conservation.component.css']
})
export class OrthologConservationComponent implements OnInit {
  ELEMENT_DATA: any; // Holds the table data
  loading: boolean = false; // Tracks whether data is being loaded

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getData(); // Load data on component initialization
  }

  getData(): void {
    this.loading = true; // Set loading to true while fetching data

    // Start timing the data fetch process
    console.time('Data Fetching Time');

    const apiUrl = `${environment.BASE_API_URL}get_otholog8k/`;
    this.fetchJson(apiUrl)
      .subscribe(
        (result) => {
          console.log('Fetched data:', result['data']);
          this.dealInfo(result['data']); // Process the fetched data
          this.loading = false; // Set loading to false once data is loaded

          // End timing and log the time taken
          console.timeEnd('Data Fetching Time');
        },
        (error) => {
          console.error('Error fetching data:', error);
          this.loading = false; // Stop loading on error

          // End timing even in case of an error
          console.timeEnd('Data Fetching Time');
        }
      );
  }

  fetchJson(filename: string) {
    const headers = new HttpHeaders();
    const options = {
      responseType: 'json' as const,
    };

    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      })
    );
  }

  dealInfo(data: any): void {
    console.log('Processing data:', data);

    // Map data to table-friendly format
    this.ELEMENT_DATA = {
      name: [
        'conservation_value',
        'conserved_or_not',
        'matched_ids',
        'go_ids',
        'sequence',
        'uniprot_ids',
        'source'
      ],
      display: [
        'Conservation Value',
        'Conserved or Not',
        'Matched IDs',
        'GO IDs',
        'Sequence',
        'Uniprot IDs',
        'Source'
      ],
      data: data.map((item: any) => ({
        conservation_value: item.conservation_value,
        conserved_or_not: item.conserved_or_not,
        matched_ids: item.matched_ids,
        go_ids: item.go_ids,
        sequence: item.sequence,
        uniprot_ids: item.uniprot_ids,
        source: item.source
      }))
    };

    console.log('Processed ELEMENT_DATA:', this.ELEMENT_DATA);
  }
}
