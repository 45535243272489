<mat-card class="bottom" id="desktop-navbar">
    <ul class="logo">
        <li>
            <a href="/home">
                <img style="height: 9vh" src="assets/images/p3dblogo_v1.png" />
            </a>
        </li>

    </ul>

    <div class="menu_table">
        <header>
            <div class="col menu col1">Browse</div>
            <div class="col menu col2"></div>
            <div class="col menu col3">Tools</div>
            <div class="col menu col4"></div>
            <div class="col menu col5">Conservation</div>
            <div class="col menu col6">Information</div>

        </header>

        <div class="menu_row">
            <div class="col menu"><a href="/countSummary">Datasets</a></div>
            <div class="col menu mobile"><a href="/ptmPeptides">PTM Data Search</a></div>
            <div class="col menu mobile"><a href="/organism-search">Organism Search</a></div>
            <!-- mobile -->
            <div class="col menu hidden" style="display: none;"><a href="/ptmPeptides">PTM Data Search</a></div>

            <div class="col menu mobile"><a href="/UniversalSearch">ID Mapping</a></div>
            <div class="col menu"><a href="/othologConservation">Ortholog</a></div>
            <div class="col menu" style="z-index: 3;">
                <!-- <a href="/cite" id="cite_link" (mouseover)="show_citation = true" (click)="openCitationLink()">
                    Cite
                </a> -->
                <a href="/cite" id="cite_link">
                    Cite
                </a>
                <div class="popup" id="cite_popup" *ngIf="show_citation" (mouseleave)="show_citation = false">
                    <p>Yao Q, Ge H, Wu S, Zhang N, Chen W, Xu C, Gao J, Thelen JJ, Xu D. (2013) P3DB 3.0: From plant phosphorylation sites to protein networks.</p>
                    <mat-icon id="copyButton" (click)="copyCitation($event)">content_copy</mat-icon>
                </div>
            </div>
        </div>
        <div class="menu_row">
            <div class="col menu"><a href="/kinase">Kinase</a></div>
            <div class="col menu mobile"><a href="/blastPtmPeptides">PTM Data Blast</a></div>
            <div class="col menu mobile"><a href="/kicnetwork">KiC Assay</a></div>
            <!-- mobile -->
            <div class="col menu hidden" style="display: none;"><a href="/blastPtmPeptides">PTM Data Blast</a></div>

            <div class="col menu mobile"><a href="/ptmPrediction">PTM Prediction</a></div>
            <div class="col menu"><a href="/ProteinConservationRegionSearch">Protein Region</a></div>
            <div class="col menu"><a href="/contact" (mouseover)="show_citation = false">Contact</a></div>


        </div>
        <div class="menu_row">
            <div class="col menu"><a href="/phylogenetic-tree">Organism</a></div>
            <div class="col menu mobile"><a href="/batchSearch">PTM Batch Search</a></div>
            <div class="col menu mobile"><a href="/ppiNetwork">PPI Network</a></div>
            <!-- mobile -->
            <div class="col menu hidden" style="display: none;"><a href="/batchSearch">PTM Batch Search</a></div>
            <div class="col menu mobile"><a href="/ptmViewer">PTM Viewer</a></div>
            <div class="col menu"><a href="/PeptideConservationSearch">Peptide Conservation</a></div>
            <div class="col menu"><a href="/team">Team</a></div>
        </div>
        <div class="menu_row">
            <div class="col menu"></div>
            <!-- <div class="col menu mobile"><a href="/message">Message AI</a></div> -->
            <div class="col menu mobile "></div>
            <div class="col menu mobile "></div>

            <!-- <div class="col menu mobile"><a href="/ProteinLogo">Protein Logo</a></div> -->
            <!-- mobile -->
            <div class="col menu hidden " style="display: none; "><a href="/organism-search ">Organism Search</a></div>
            <div class="col menu mobile "></div>

            <!-- <div class="col menu mobile"><a href="/UniversalSearch">Universal Search</a></div> -->
            <div class="col menu"></div>
            <div class="col menu"></div>


        </div>

        <div class=" menu_row ">
            <div class="col menu "></div>
            <div class="col menu mobile "></div>
            <div class="col menu mobile "></div>
            <!-- <div class="col menu mobile "><a href="/ptmPeptides ">PTM Peptides</a></div> -->
            <!-- mobile -->
            <div class="col menu hidden " style="display: none; "><a href="/kicnetwork">KiC Assay</a></div>
            <div class="col menu mobile "></div>
            <div class="col menu "></div>
            <div class="col menu "></div>

        </div>


        <!-- mobile views -->
        <div class="menu_row hidden ">
            <div class="col menu "></div>
            <div class="col menu mobile "></div>
            <div class="col menu "><a href="/ppiNetwork">PPI Network</a></div>
            <div class="col menu mobile "></div>
            <div class="col menu "></div>
            <div class="col menu "></div>
        </div>

        <div class="menu_row hidden ">
            <div class="col menu "></div>
            <div class="col menu mobile "></div>
            <div class="col menu "><a href="/UniversalSearch">ID Mapping</a></div>
            <div class="col menu mobile "></div>
            <div class="col menu "></div>
            <div class="col menu "></div>
        </div>

        <div class="menu_row hidden ">
            <div class="col menu "></div>
            <div class="col menu mobile "></div>
            <div class="col menu "><a href="/ptmPrediction">PTM Prediction</a></div>
            <div class="col menu mobile "></div>
            <div class="col menu "></div>
            <div class="col menu "></div>
        </div>

        <div class="menu_row hidden ">
            <div class="col menu "></div>
            <div class="col menu mobile "></div>
            <div class="col menu "><a href="/show3D">3D Structure Viewer</a></div>
            <div class="col menu mobile "></div>
            <div class="col menu "></div>
            <div class="col menu "></div>
        </div>

        <div class="menu_row hidden ">
            <div class="col menu "></div>
            <div class="col menu mobile "></div>
            <div class="col menu "></div>
            <div class="col menu mobile "></div>
            <div class="col menu "></div>
            <div class="col menu "></div>
        </div>

        <div class="menu_row hidden ">
            <div class="col menu "></div>
            <div class="col menu mobile "></div>
            <div class="col menu "></div>
            <div class="col menu mobile "></div>
            <div class="col menu "></div>
            <div class="col menu "></div>
        </div>

        <div class="menu_row hidden ">
            <div class="col menu "></div>
            <div class="col menu mobile "></div>
            <div class="col menu "></div>
            <div class="col menu mobile "></div>
            <div class="col menu "></div>
            <div class="col menu "></div>
        </div>

        <div class="menu_row hidden ">
            <div class="col menu "></div>
            <div class="col menu mobile "></div>
            <div class="col menu "></div>
            <div class="col menu mobile "></div>
            <div class="col menu "></div>
            <div class="col menu "></div>
        </div>


        <!-- end mobile views -->
    </div>

    <ul class="logo " style="margin-top: 10px; ">
        <li>
            <a href="https://www.unl.edu/ " target="_blank ">
                <img src="assets/images/logoOfUNL2.png " style="height: 7vh; margin: 1vh; margin-top: 2vh; " />
            </a>
        </li>

        <li>
            <a href="https://missouri.edu/ " target="_blank ">
                <img src="assets/images/logoOfMU2.png" style="height: 9vh; margin-left: 1vh; " />
            </a>
        </li>

        <li>
            <a href="https://www.unr.edu/ " target="_blank ">
                <img src="assets/images/logoOfUNR2.png " style="height: 7vh; margin: 1vh; " />
            </a>
        </li>

        <li>
            <a href="https://www.uga.edu/ " target="_blank ">
                <img src="assets/images/UGA_logo.png " style="height: 7vh; margin: 1vh; " />
            </a>
        </li>

        <li>
            <a href="https://wsu.edu/ " target="_blank ">
                <img src="assets/images/WSU_logo.png " style="height: 7vh; margin: 0vh; " />
            </a>
        </li>
    </ul>

    <p class = "trademark">© 2010 — 2025 P3DB</p>

    <!-- <mat-card class="bottom-right-card">
        <div id="mapmyvisitors" class="map">
            <a style="display: none;" href='https://mapmyvisitors.com/web/1bvpn' title='Visit tracker'><img src='https://mapmyvisitors.com/map.png?cl=ffffff&w=a&t=n&d=jBSGfAxOjmSQ7IKqiLuFT26u6AddCBn2dyzXVK4e3XY' /></a>
            <p> <span class="view_counter">{{view_count }} </span> <br>hits on p3db.org</p>
        </div>
    </mat-card> -->

</mat-card>
